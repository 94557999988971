export const references = [
  {
    id: '1',
    namespaceId: 'eip155',
    name: 'Ethereum Mainnet',
    label: 'ETH',
    explorer: 'etherscan.io',
  },
  {
    id: '56',
    namespaceId: 'eip155',
    name: 'Binance Smart Chain',
    label: 'BSC',
    explorer: 'bscscan.com',
  },
  {
    id: '100',
    namespaceId: 'eip155',
    name: 'Gnosis Chain',
    label: 'Gnosis',
    explorer: 'gnosisscan.io',
  },
  {
    id: '137',
    namespaceId: 'eip155',
    name: 'Polygon',
    label: 'MATIC',
    explorer: 'polygonscan.com',
  },
  {
    id: '000000000019d6689c085ae165831e93',
    namespaceId: 'bip122',
    name: 'Bitcoin',
    label: 'BTC',
    explorer: 'mempool.space',
  },
  {
    id: '4sGjMW1sUnHzSxGspuhpqLDx6wiyjNtZ',
    namespaceId: 'solana',
    name: 'Solana',
    label: 'SOL',
    explorer: 'explorer.solana.com',
  },
  {
    id: '8453',
    namespaceId: 'eip155',
    name: 'Base Mainnet',
    label: 'Base',
    explorer: 'basescan.org',
  },
  {
    id: '42161',
    namespaceId: 'eip155',
    name: 'Arbitrum One',
    label: 'ARB',
    explorer: 'arbiscan.io',
  },
  {
    id: '1284',
    namespaceId: 'eip155',
    name: 'Moonbeam',
    label: 'GLMR',
    explorer: 'moonscan.io',
  },
  {
    id: '59144',
    namespaceId: 'eip155',
    name: 'Linea',
    label: 'Linea',
    explorer: 'lineascan.build',
  },
  {
    id: '10',
    namespaceId: 'eip155',
    name: 'Optimism',
    label: 'OP',
    explorer: 'optimistic.etherscan.io',
  },
  {
    id: '250',
    namespaceId: 'eip155',
    name: 'Fantom Opera',
    label: 'FTM',
    explorer: 'ftmscan.com',
  },
  {
    id: '1285',
    namespaceId: 'eip155',
    name: 'Moonriver',
    label: 'MOVR',
    explorer: 'moonriver.moonscan.io',
  },
  {
    id: '43114',
    namespaceId: 'eip155',
    name: 'Avalanche C-Chain',
    label: 'AVAX',
    explorer: 'snowscan.xyz',
  },
  {
    id: '25',
    namespaceId: 'eip155',
    name: 'Cronos Mainnet',
    label: 'CRO',
    explorer: 'cronoscan.com',
  },
  {
    id: '199',
    namespaceId: 'eip155',
    name: 'BitTorrent Chain Mainnet',
    label: 'BTT',
    explorer: 'bttcscan.com',
  },
  {
    id: '1101',
    namespaceId: 'eip155',
    name: 'Polygon zkEVM',
    label: 'ETH',
    explorer: 'zkevm.polygonscan.com',
  },
  {
    id: '1111',
    namespaceId: 'eip155',
    name: 'WEMIX3.0 Mainnet',
    label: 'WEMIX',
    explorer: 'wemixscan.com',
  },
  {
    id: '534352',
    namespaceId: 'eip155',
    name: 'Scroll',
    label: 'Scroll',
    explorer: 'scrollscan.com',
  },
  {
    id: '324',
    namespaceId: 'eip155',
    name: 'zkSync',
    label: 'zkSync',
    explorer: 'era.zksync.network',
  },
  {
    id: '42220',
    namespaceId: 'eip155',
    name: 'Celo',
    label: 'CELO',
    explorer: 'celoscan.io',
  },
]
